<template>
  <div class="checkout-product-summary card">
    <div class="card-header bg-success text-light">
      <img src="@/assets/images/shield.svg" />
      <small>COMPRA 100% SEGURA</small>
    </div>
    <div class="card-body" v-if="product && product.prod_id">
      <!-- <img class="mb-4" :src="product.cover" /> -->
      <small>RESUMO DA COMPRA</small>
      <h6>{{ product.name }}</h6>
      <div class="description" v-html="product.description"></div>

      <div v-if="acceptOffer" class="bump-offer">
        <hr />
        <small v-for="(p, i) in product.offers.products" :key="i">
          + {{ p.name }}
        </small>
        <small>De <del>R$ 1.200</del> por R$ 600</small>
      </div>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-between">
      <strong>Total</strong>
      <strong>R$ {{ finalPrice }}</strong>
    </div>
  </div>
</template>

<script>
import { formatMoney } from "@/functions/helpers.js";
import get from "lodash/get";

export default {
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    acceptOffer() {
      return this.$store.state.acceptOffer;
    },
    finalPrice() {
      if (this.acceptOffer) {
        return this.offerPrice;
      }
      return formatMoney(
        parseFloat(this.product.price_card) -
          parseFloat(this.product.discount || 0)
      );
    },
    offerPrice() {
      return formatMoney(get(this.product, "offers.order_bump_price"));
    }
  }
};
</script>

<style lang="scss" scoped>
.checkout-product-summary {
  overflow: hidden;

  .card-header,
  .card-footer {
    background: #e5e5e5;
  }

  .card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 0;

    small {
      font-weight: bold;
    }

    img {
      margin-right: 2px;
      max-height: 32px;
      max-width: 32px;
    }
  }

  img {
    width: 96px;
    height: 96px;
    object-fit: cover;
  }

  .card-body {
    h6 {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #272d32;
    }

    .description {
      font-size: 10px;
      line-height: 12px;
      color: #666;
    }

    small {
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: bold;
      color: #bbb;
    }
  }

  .bump-offer {
    small {
      display: block;
      letter-spacing: 0;
      color: #999;
    }
  }
}
</style>
