import Vue from "vue";

import ActionDispatcher from "./ActionDispatcher.vue";
import FormGroup from "./FormGroup.vue";
import Loading from "./Loading.vue";
import Snackbar from "./Snackbar.vue";

Vue.component("action-dispatcher", ActionDispatcher);
Vue.component("form-group", FormGroup);
Vue.component("loading", Loading);
Vue.component("snackbar", Snackbar);
