export function toHumanDate(date) {
  return date
    .split("-")
    .reverse()
    .join("/");
}

export function dayDiff(dateString) {
  var today = new Date();
  var date = new Date(dateString);

  // To calculate the time difference of two dates
  var differenceInTime = date.getTime() - today.getTime();

  // To calculate the no. of days between two dates
  return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export const emojis = [
  "😁",
  "😂",
  "😃",
  "😄",
  "😅",
  "😆",
  "😉",
  "😊",
  "😋",
  "😌",
  "😍",
  "😏",
  "😒",
  "😓",
  "😔",
  "😖",
  "😘",
  "😚",
  "😜",
  "😝",
  "😞",
  "😠",
  "😢",
  "😤",
  "😥",
  "😨",
  "😩",
  "😪",
  "😫",
  "😭",
  "😰",
  "😱",
  "😲",
  "😳",
  "😵",
  "😷",
  "😸",
  "😹",
  "😺",
  "😻",
  "😼",
  "😽",
  "😾",
  "😿",
  "🙀",
  "🙅",
  "🙆",
  "🙇",
  "🙈",
  "🙉",
  "🙊",
  "🙋",
  "🙌",
  "🙍",
  "🙎",
  "🙏"
];

export const formFields = (fields, initial = {}) => {
  const form = {};
  const errors = {};
  for (const i in fields) {
    const field = fields[i];
    form[field] = initial && initial[field] ? initial[field] : "";
    errors[field] = [];
  }
  return { form, errors, loading: false };
};

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ",",
  thousands = "."
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    return "0,00";
  }
};

export const clearObject = object => {
  const newObject = { ...object };
  for (const i in newObject) {
    if (
      newObject[i] === "" ||
      newObject[i] === undefined ||
      newObject[i] === null
    ) {
      delete newObject[i];
    }
  }
  return newObject;
};

export const states = [
  { name: "Acre", id: "AC" },
  { name: "Alagoas", id: "AL" },
  { name: "Amapá", id: "AP" },
  { name: "Amazonas", id: "AM" },
  { name: "Bahia", id: "BA" },
  { name: "Ceará", id: "CE" },
  { name: "Distrito Federal", id: "DF" },
  { name: "Espírito Santo", id: "ES" },
  { name: "Goiás", id: "GO" },
  { name: "Maranhão", id: "MA" },
  { name: "Mato Grosso", id: "MT" },
  { name: "Mato Grosso do Sul", id: "MS" },
  { name: "Minas Gerais", id: "MG" },
  { name: "Pará", id: "PA" },
  { name: "Paraíba", id: "PB" },
  { name: "Paraná", id: "PR" },
  { name: "Pernambuco", id: "PE" },
  { name: "Piauí", id: "PI" },
  { name: "Rio de Janeiro", id: "RJ" },
  { name: "Rio Grande do Norte", id: "RN" },
  { name: "Rio Grande do Sul", id: "RS" },
  { name: "Rondônia", id: "RO" },
  { name: "Roraima", id: "RR" },
  { name: "Santa Catarina", id: "SC" },
  { name: "São Paulo", id: "SP" },
  { name: "Sergipe", id: "SE" },
  { name: "Tocantins", id: "TO" }
];

export const storeObject = (key, object) => {
  window.localStorage.setItem(key, JSON.stringify(object));
};

export const retrieveObject = key => {
  const item = window.localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return {};
};
