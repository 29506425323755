<template>
  <div class="template-power">
    <main class="py-lg-4">
      <div class="container pb-5 mb-5">
        <header class="text-center pt-5 pb-4 mb-n3 d-none d-lg-block">
          <small class="d-block mt-5 mb-1 pt-5"
            >NOVO CURSO DE OLAVO DE CARVALHO</small
          >

          <h1>
            CIÊNCIA POLÍTICA:<br />
            SABER, PREVER e PODER
          </h1>
        </header>

        <slot />
      </div>
    </main>

    <footer class="pb-5 pt-4 bg-white text-center text-lg-left">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <img class="img-fluid" src="@/assets/images/odec.png" />
          </div>
          <div class="col-lg-3 d-flex flex-column">
            <a
              class="text-dark font-weight-bold text-decoration-none py-1"
              href="https://app.seminariodefilosofia.org/termos-de-uso"
              target="_blank"
            >
              Termos de uso
            </a>
            <a
              class="text-dark font-weight-bold text-decoration-none py-1"
              href="https://app.seminariodefilosofia.org/politica-de-privacidade"
              target="_blank"
            >
              Política de privacidade
            </a>
          </div>
        </div>
      </div>
    </footer>

    <wpp-button
      text='Olá, equipe do Seminário de Filosofia. Quero assinar o curso "Ciência Política: Saber, Prever e Poder".'
    />
  </div>
</template>

<script>
import WppButton from "../components/WppButton";

export default {
  components: { WppButton }
};
</script>

<style lang="scss" scoped>
.template-power {
  background: white;

  header {
    background: url("../assets/images/olavo-header.png");
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;

    small {
      color: white;
      letter-spacing: 4px;
      text-shadow: 0 0 8px black;
    }

    h1 {
      font-family: "britania_ligatura", Georgia, "Times New Roman", Times, serif;
      color: #fec93f;
      font-size: 30px;
      line-height: 32px;
      text-shadow: 0 0 8px black;
    }
  }

  main {
    background-color: rgb(27, 25, 23);
    background-image: url("../assets/images/header-bg.jpg"),
      url("../assets/images/header-bg-low.jpg");
    background-size: cover;
    background-position-y: top;
    background-position-x: center;

    /deep/ .card {
      overflow: hidden;
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6);

      @media screen and (max-width: 991px) {
        border-radius: 0 !important;
        box-shadow: none !important;
      }
    }

    @media screen and (max-width: 991px) {
      background: white;

      .container {
        min-width: 100%;
        max-width: 100%;
        padding: 0;
        overflow: hidden;
      }
    }
  }

  /deep/ .nav-link {
    transition: 0.2s color;
    margin-right: 16px;
    color: #979797;
  }

  /deep/ .router-link-exact-active {
    font-weight: bold;
    color: #0088f2;
  }
}
</style>
