<template>
  <form class="checkout-billet-form" @submit.prevent="submit">
    <div class="form-row">
      <div class="col-lg-4">
        <form-group
          id="zipcode"
          type="tel"
          v-model="form.zipcode"
          :errors="errors.zipcode"
          placeholder="Digite o CEP"
          label="CEP*"
          mask="#####-###"
          @blur="searchCep"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          id="address"
          v-model="form.address"
          :errors="errors.address"
          placeholder="Digite o seu endereço"
          label="Endereço completo*"
          :disabled="searchingZipcode"
        />
      </div>
      <div class="col-lg-2">
        <form-group
          id="number"
          type="tel"
          v-model="form.number"
          :errors="errors.number"
          placeholder="0000"
          label="Número*"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="col-lg-6">
        <form-group
          id="complement"
          v-model="form.complement"
          :errors="errors.complement"
          placeholder="Ex: Apartamento 1"
          label="Complemento (opcional)"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          id="neighborhood"
          v-model="form.neighborhood"
          :errors="errors.neighborhood"
          placeholder="Digite seu bairro"
          label="Bairro*"
          :disabled="searchingZipcode"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="col-lg-6">
        <form-group
          id="city"
          v-model="form.city"
          :errors="errors.city"
          placeholder="Digite sua cidade"
          label="Cidade*"
          :disabled="searchingZipcode"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          id="state"
          v-model="form.state"
          :errors="errors.state"
          select
          :options="states"
          placeholder="Digite seu estado"
          label="Estado*"
          :disabled="searchingZipcode"
        />
      </div>
    </div>

    <order-bump />

    <div class="text-right">
      <button type="submit" class="btn btn-success px-5 font-weight-bold">
        <loading :show="loading">Gerar boleto</loading>
      </button>
    </div>
  </form>
</template>

<script>
import OrderBump from "./OrderBump";
import { formFields, states } from "@/functions/helpers.js";
import { mapState } from "vuex";

export default {
  components: { OrderBump },
  data() {
    return {
      states,
      loading: false,
      searchingZipcode: false,
      ...formFields([
        "zipcode",
        "address",
        "number",
        "complement",
        "city",
        "neighborhood",
        "state"
      ])
    };
  },
  computed: {
    ...mapState(["code", "acceptOffer"])
  },
  methods: {
    searchCep() {
      if (!this.form.zipcode.trim) return;
      this.searchingZipcode = true;

      window.$.getJSON(
        `https://viacep.com.br/ws/${this.form.zipcode}/json/?callback=?`,
        data => {
          if (!("erro" in data)) {
            this.form.address = data.logradouro;
            this.form.neighborhood = data.bairro;
            this.form.city = data.localidade;
            this.form.state = data.uf;
          }
        }
      ).always(() => {
        this.searchingZipcode = false;
      });
    },
    sendBillet(token) {
      this.$store
        .dispatch("sendBillet", {
          code: this.code,
          data: {
            ...this.form,
            is_offer: this.acceptOffer,
            token
          }
        })
        .then(response => {
          this.$emit("success", { link: response.data.link });
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      if (this.loading) return;
      this.loading = true;

      window.grecaptcha.execute({ action: "submit" }).then(token => {
        this.sendBillet(token);
      });
    }
  }
};
</script>
