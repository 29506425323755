<template>
  <div class="checkout-step-2">
    <payment-type-selector v-if="!hideBillet" v-model="selected" class="mb-4" />

    <card-form
      v-if="hideBillet || selected === 'card'"
      @success="$emit('success')"
    />

    <billet-form
      v-if="!hideBillet && selected === 'billet'"
      @success="data => $emit('success', data)"
    />
  </div>
</template>

<script>
import CardForm from "./CardForm";
import BilletForm from "./BilletForm";
import PaymentTypeSelector from "./PaymentTypeSelector";

export default {
  components: { CardForm, BilletForm, PaymentTypeSelector },
  props: {
    hideBillet: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: "card",
      options: [
        { text: "Cartão de crédito", value: "card" },
        { text: "Boleto", value: "billet" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.checkout-step-2 {
  .btn-back {
    font-size: 12px;
    font-weight: bold;
  }

  /deep/ legend {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #979797;
  }

  /deep/ .btn-group span {
    font-weight: bold;
    padding: 0 8px;
  }
}
</style>
