export default {
  setAcceptOffer(state, acceptOffer) {
    state.acceptOffer = acceptOffer;
  },
  setCode(state, code) {
    state.code = code;
  },
  setInstallments(state, installments) {
    state.installments = installments;
  },
  setProduct(state, product) {
    state.product = product;
  },
  setCountries(state, countries) {
    const result = [];
    for (let index in countries) {
      for (let key in countries[index]) {
        result.push({ id: key, name: countries[index][key] });
      }
    }
    state.countries = result;
  }
};
