<template>
  <div class="checkout-credit-card">
    <div class="d-flex">
      <img src="@/assets/images/chip.svg" />
      <span class="flex-fill"></span>
      <span>{{ data.card_cvv ? data.card_cvv : "***" }}</span>
    </div>
    <div class="pt-4 pb-3">
      {{ cardNumber ? cardNumber : "**** **** **** ****" }}
    </div>
    <div class="d-flex">
      <span>{{ holderName ? holderName : "***** * *****" }}</span>
      <span class="flex-fill"></span>
      <span>{{ data.card_expiration ? data.card_expiration : "**/**" }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    cardNumber() {
      let result = "";
      for (let i in this.data.card_number) {
        result += this.data.card_number[i];

        if (i % 4 === 3) {
          result += " ";
        }
      }
      return result;
    },
    holderName() {
      return this.data.holder_name.toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.checkout-credit-card {
  background: rgba(229, 229, 229, 0.5);
  border: 1px solid rgba(151, 151, 151, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 60px 24px 16px;
  letter-spacing: 0.16em;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  font-family: monospace;
  color: #979797;
  max-width: 400px;
}
</style>
