import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/britanialigatura/stylesheet.css";
import "./assets/icomoon/style.css";
import "./assets/vue-tel-input.css";
import "./assets/scss/app.scss";
import "./components";
import "./plugins";
import { init } from "./setup/api";

import VueTheMask from "vue-the-mask";

Vue.use(BootstrapVue);
Vue.use(VueTheMask);

init();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

window._grecaptcha_callback = () => {
  window.grecaptcha.render({
    sitekey: "6LffniUaAAAAAJLLZgPTRXj_17tS59GQKYD49mqo",
    badge: "bottomleft"
  });
};
