import Vue from "vue";
import Router from "vue-router";
// import VueGtm from "vue-gtm";

import Checkout from "./views/Checkout.vue";
import DefaultTemplate from "./templates/Default.vue";
import Power from "./templates/Power.vue";

Vue.use(Router);

const vueRouter = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: "/comprar",
    //   name: "special",
    //   component: Checkout,
    //   meta: {
    //     template: Power,
    //     protected: false
    //   }
    // },
    {
      path: "/:id",
      name: "checkout",
      component: Checkout,
      meta: {
        template: DefaultTemplate,
        protected: false
      }
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("./views/NotFound.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    }
  ]
});

// Vue.use(VueGtm, {
//   id: "GTM-TB4VDDF",
//   vueRouter
// });

export default vueRouter;
