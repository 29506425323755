export default {
  getProduct(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/payments/sales/products/${id}`)
        .then(response => {
          context.commit("setProduct", response.data);
          resolve();
        })
        .catch(reject);
    });
  },

  getCountries(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/sales/countries")
        .then(response => {
          context.commit("setCountries", response.data);
          resolve();
        })
        .catch(reject);
    });
  },

  sendCustomer(context, { id, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/payments/shopper/${id}`, data)
        .then(response => {
          context.commit("setCode", response.data.code);
          context.commit("setInstallments", response.data.installments);
          resolve();
        })
        .catch(reject);
    });
  },

  sendCard(context, { code, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/payments/card/${code}`, data)
        .then(resolve)
        .catch(reject);
    });
  },

  sendBillet(context, { code, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/payments/billet/${code}`, data)
        .then(resolve)
        .catch(reject);
    });
  }
};
