<template>
  <form
    class="checkout-step-1 position-relative pt-lg-3"
    @submit.prevent="submit"
  >
    <country-selector v-model="form.country" />

    <div class="form-row">
      <div class="col-lg-6">
        <form-group
          id="name"
          v-model="form.name"
          :errors="errors.name"
          placeholder="Digite o seu nome completo"
          label="Nome completo*"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          id="email"
          type="email"
          v-model="form.email"
          :errors="errors.email"
          placeholder="Digite o seu e-mail"
          label="Endereço de e-mail*"
        />
      </div>
    </div>
    <div class="form-row mb-5">
      <div class="col-lg-6">
        <form-group
          id="document"
          type="tel"
          v-model="form.document"
          :errors="errors.document"
          :placeholder="
            isBrazil ? 'Digite o número do seu CPF' : 'Digite o seu documento'
          "
          :label="isBrazil ? 'Número do CPF*' : 'Documento estrangeiro'"
          :mask="documentMask"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          id="cellphone"
          type="tel"
          v-model="form.cellphone"
          :errors="errors.cellphone"
          placeholder="Digite o número do seu celular"
          label="Celular com DDD*"
          :mask="phoneMask"
        />
      </div>
    </div>

    <div class="text-right">
      <button class="btn btn-success px-5 font-weight-bold">
        <loading :show="loading">Próximo passo</loading>
      </button>
    </div>
  </form>
</template>

<script>
import {
  formFields,
  storeObject,
  retrieveObject
} from "@/functions/helpers.js";
import { mapState } from "vuex";
import CountrySelector from "../CountrySelector";

export default {
  components: { CountrySelector },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchingZipcode: false,
      loading: false,
      ...formFields(["name", "document", "email", "cellphone", "country"], {
        ...retrieveObject("_step1"),
        country: "BR"
      })
    };
  },
  computed: {
    ...mapState(["countries"]),
    isBrazil: function() {
      return this.form.country === "BR";
    },
    documentMask: function() {
      return this.isBrazil ? ["###.###.###-##", "##.###.###/####-##"] : null;
    },
    phoneMask: function() {
      return this.isBrazil ? ["(##) #####-####"] : "##################";
    }
  },
  beforeDestroy() {
    storeObject("_step1", this.form);
  },
  methods: {
    sendCustomer(token) {
      this.$store
        .dispatch("sendCustomer", {
          id: this.id,
          data: {
            ...this.form,
            token,
            data: this.$route.query
          }
        })
        .then(() => {
          this.$emit("success", this.form);
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      if (this.loading) return;
      this.loading = true;

      window.grecaptcha.execute({ action: "submit" }).then(token => {
        this.sendCustomer(token);
      });
    }
  }
};
</script>
