<template>
  <div class="checkout-payment-type-selector d-flex">
    <button
      type="button"
      class="btn"
      :class="{ current: value === 'card' }"
      @click="$emit('input', 'card')"
    >
      <i class="icon-credit-card"></i>
      Cartão de crédito
    </button>
    <button
      type="button"
      class="btn"
      :class="{ current: value === 'billet' }"
      @click="$emit('input', 'billet')"
    >
      <i class="icon-bar-code"></i>
      Boleto Bancário
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.checkout-payment-type-selector {
  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(33, 38, 49, 0.4);
    color: rgba(33, 38, 49, 0.4);
    padding: 8px;
    width: 140px;
    font-size: 12px;
    line-height: 16px;
    margin-right: 16px;

    i {
      font-size: 24px;
      margin-bottom: 2px;
    }

    &.current {
      border-color: #3cc13b;
      color: #3cc13b;
      font-weight: bold;
    }
  }
}
</style>
