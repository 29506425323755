<template>
  <div class="view-checkout">
    <action-dispatcher action="getProduct" :parameters="id">
      <div class="row">
        <div class="col-lg-3 order-lg-2">
          <product-summary :product="product" />
        </div>

        <div class="col-lg-9 order-lg-1">
          <div class="card">
            <card-header :current="step" @back="step = 1" />

            <div class="card-body">
              <step-1 v-if="step === 1" :id="id" @success="onStep1Success" />
              <step-2
                v-if="step === 2"
                :hide-billet="isForeign"
                @success="onStep2Success"
                @back="step = 1"
              />
              <step-3 v-if="step === 3" :link="link" />
            </div>
          </div>
        </div>
      </div>
    </action-dispatcher>
    <wpp-button />
  </div>
</template>

<script>
import CardHeader from "@/components/checkout/CardHeader";
import ProductSummary from "@/components/checkout/ProductSummary";
import Step1 from "@/components/checkout/Step1";
import Step2 from "@/components/checkout/Step2";
import Step3 from "@/components/checkout/Step3";
import WppButton from "@/components/WppButton";
import { mapState } from "vuex";

export default {
  components: { CardHeader, Step1, Step2, Step3, ProductSummary, WppButton },
  data() {
    return {
      step: 1,
      link: null,
      isForeign: false
    };
  },
  computed: {
    ...mapState(["product"]),
    id() {
      return this.$route.params.id ? this.$route.params.id : "PRVHE391";
    }
  },
  methods: {
    onStep1Success(data) {
      if (data.country !== "BR") {
        this.isForeign = true;
      }
      this.step = 2;
    },
    onStep2Success(data = null) {
      if (data && data.link) {
        this.link = data.link;
      }
      this.step = 3;
    }
  }
};
</script>

<style lang="scss" scoped>
.view-checkout {
  .card {
    overflow: hidden;
  }
}
</style>
