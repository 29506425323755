<template>
  <button class="wpp-button" @click="click">
    <img src="@/assets/images/whatsapp.png" />
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Olá, eu preciso de suporte"
    }
  },
  methods: {
    click() {
      const phones = ["5521970938909"];
      const randomPhone = phones[Math.floor(Math.random() * phones.length)];
      window.open(
        `https://api.whatsapp.com/send?phone=${randomPhone}&text=${this.text}`,
        "_blank"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.wpp-button {
  background: transparent;
  border: none;
  padding: 0;
  position: fixed;
  bottom: 8px;
  right: 8px;
  z-index: 9;

  img {
    width: 60px;
    height: 60px;
  }
}
</style>
