<template>
  <div class="country-selector" v-click-outside="clickOutside">
    <action-dispatcher action="getCountries" small>
      <button class="btn btn-toggle btn-sm" @click="toggle" type="button">
        <div class="vti__flag" :class="value.toLowerCase()"></div>
        <span class="mr-2 d-none d-lg-block">Alterar país</span>
        <i class="icon-caret-down"></i>
      </button>
      <div v-if="showing" class="card">
        <input
          ref="input"
          type="text"
          v-model="search"
          placeholder="Pesquisar..."
        />
        <button
          v-for="(country, index) in countriesToShow"
          class="item"
          :class="{ active: country.id === value }"
          type="button"
          @click="select(country)"
          :key="index"
        >
          <div class="vti__flag" :class="country.id.toLowerCase()"></div>
          <span>{{ country.name }}</span>
        </button>
      </div>
    </action-dispatcher>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  directives: { ClickOutside },
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      search: "",
      showing: false
    };
  },
  computed: {
    ...mapState(["countries"]),
    countriesToShow() {
      var search = this.search.toLowerCase();
      return this.countries.filter(
        country => country.name.toLowerCase().indexOf(search) >= 0
      );
    }
  },
  methods: {
    select(country) {
      this.$emit("input", country.id);
      this.showing = false;
    },
    toggle() {
      this.showing = !this.showing;
      if (this.showing) {
        setTimeout(() => {
          this.$refs.input.focus();
        }, 1);
      }
    },
    clickOutside() {
      this.showing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.country-selector {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  .btn-toggle {
    background: none;
    border: none;
    padding: 0;
    font-weight: bold;
    display: flex;
    align-items: center;

    &:focus {
      box-shadow: none !important;
    }
  }

  .card {
    border-radius: 16px !important;
    position: absolute;
    z-index: 2;
    max-height: 240px;
    overflow-y: scroll !important;
    width: 300px;
    right: 0;
    top: 30px;
    padding: 4px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;

    input {
      background: transparent;
      padding: 6px 12px;
      border: none;
    }

    .item {
      padding: 6px 12px;
      padding: 6px 12px;
      display: flex;
      align-items: center;
      text-align: left;
      background: white;
      border: none;

      &.active {
        background-color: rgba(100, 120, 140, 0.05);
      }

      &:hover {
        background-color: rgba(80, 100, 120, 0.1);
      }

      i {
        font-size: 8px;
      }
    }
  }
}
</style>
