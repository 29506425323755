<template>
  <div class="checkout-step-3">
    <h1 class="p-5 text-center text-success">
      <div class="icon-wrapper">
        <i class="icon-check text-success"></i>
      </div>
      <br />
      Obrigado pela compra!
    </h1>

    <div class="text-center">
      <a
        v-if="link"
        class="btn btn-success px-4 font-weight-bold"
        :href="link"
        target="_blank"
      >
        Abrir boleto
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.checkout-step-3 {
  .icon-wrapper {
    border: 3px solid #3cc13b;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 64px;
    min-width: 64px;
    max-height: 64px;
    max-width: 64px;
    border-radius: 64px;
    margin: auto;

    i {
      font-size: 48px;
    }
  }
}
</style>
