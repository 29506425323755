<template>
  <div
    v-if="product.offers && product.offers.has_order_bump"
    class="checkout-order-bump"
  >
    <div class="d-flex justify-content-center">
      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="acceptOffer">
          Quero aproveitar e comprar junto
        </b-form-checkbox>
      </div>
    </div>
    <strong
      >Aproveite e garanta o TOP 3 dos cursos mais vendidos com 50% OFF</strong
    >
    <div class="item" v-for="(p, i) in product.offers.products" :key="i">
      - Curso {{ i + 1 }}: {{ p.name }}
    </div>
    <h6>De <del>R$ 1.200</del> por R$ 600</h6>
  </div>
</template>

<script>
import { formatMoney } from "@/functions/helpers.js";
import { mapState } from "vuex";
import get from "lodash/get";

export default {
  computed: {
    ...mapState(["product"]),
    acceptOffer: {
      get() {
        return this.$store.state.acceptOffer;
      },
      set(value) {
        this.$store.commit("setAcceptOffer", value);
      }
    },
    offerPrice() {
      return formatMoney(get(this.product, "offers.order_bump_price"));
    }
  }
};
</script>

<style lang="scss" scoped>
.checkout-order-bump {
  border: 2px dashed #d22;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  margin-bottom: 16px;

  .item {
    font-size: 14px;
    font-weight: bold;
    color: #999;
  }

  strong {
    color: #d22;
  }

  h6 {
    margin: 8px 0 0;
  }

  .checkbox-wrapper {
    padding: 4px 8px;
    border: 1px solid #bbb;
    border-radius: 4px;
    margin-bottom: 4px;
    font-weight: bold;
  }
}
</style>
