<template>
  <form class="checkout-card-form" @submit.prevent="submit">
    <div class="row">
      <div class="col-lg-6">
        <form-group
          id="card_number"
          type="tel"
          v-model="form.card_number"
          :errors="errors.card_number"
          placeholder="**** **** **** ****"
          mask="#### #### #### ####"
          label="Número do cartão*"
        />
        <form-group
          id="holder_name"
          v-model="form.holder_name"
          :errors="errors.holder_name"
          placeholder="Digite o nome do titular"
          label="Titular do cartão*"
        />
        <div class="form-row">
          <div class="col-lg-8">
            <form-group
              id="card_expiration"
              type="tel"
              v-model="form.card_expiration"
              :errors="errors.card_expiration"
              placeholder="MM/AAAA"
              mask="##/####"
              label="Validade*"
              masked
            />
          </div>
          <div class="col-lg-4">
            <form-group
              id="card_cvv"
              type="tel"
              v-model="form.card_cvv"
              :errors="errors.card_cvv"
              placeholder="CVV"
              label="Código*"
              mask="####"
            />
          </div>
        </div>
        <form-group
          id="holder_birthday"
          type="tel"
          v-model="form.holder_birthday"
          :errors="errors.holder_birthday"
          placeholder="DD/MM/AAAA"
          label="Data de nascimento do titular*"
          mask="##/##/####"
          masked
        />
        <form-group
          v-if="installments > 1"
          id="installments"
          v-model="form.installments"
          :errors="errors.installments"
          select
          :options="installmentOptions"
          label="Parcelamento"
        />
      </div>
      <div class="col-lg-6 pb-4">
        <credit-card :data="form" class="ml-auto" />
      </div>
    </div>

    <order-bump />

    <div class="text-right">
      <button type="submit" class="btn btn-success px-5 font-weight-bold">
        <loading :show="loading">Finalizar compra</loading>
      </button>
    </div>
  </form>
</template>

<script>
import CreditCard from "./CreditCard";
import { formFields } from "@/functions/helpers.js";
import { mapState } from "vuex";
import { formatMoney } from "@/functions/helpers.js";
import OrderBump from "./OrderBump";
import get from "lodash/get";

export default {
  components: { CreditCard, OrderBump },
  data() {
    return {
      loading: false,
      ...formFields(
        [
          "card_number",
          "holder_name",
          "card_expiration",
          "card_cvv",
          "holder_birthday",
          "installments"
        ],
        {
          installments: 1
        }
      )
    };
  },
  computed: {
    ...mapState(["code", "installments", "product", "acceptOffer"]),
    price() {
      if (this.acceptOffer) {
        return get(this.product, "offers.order_bump_price");
      }
      return (
        parseFloat(this.product.price_card) -
        parseFloat(this.product.discount || 0)
      );
    },
    installmentOptions() {
      const options = [];
      for (let i = 1; i <= this.installments; i++) {
        options.push({
          id: i,
          name: `${i}x R$ ${formatMoney(this.price / i)}`
        });
      }
      return options;
    }
  },
  methods: {
    sendCard(token) {
      this.$store
        .dispatch("sendCard", {
          code: this.code,
          data: {
            ...this.form,
            is_offer: this.acceptOffer,
            token
          }
        })
        .then(() => {
          this.$emit("success");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      if (this.loading) return;
      this.loading = true;

      window.grecaptcha.execute({ action: "submit" }).then(token => {
        this.sendCard(token);
      });
    }
  }
};
</script>
