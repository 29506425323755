<template>
  <div class="checkout-card-header d-flex border-bottom">
    <div
      class="card-header"
      :class="{ current: current === 1, pointer: current === 2 }"
      @click="click1"
    >
      <span class="number">1</span> Dados pessoais
    </div>
    <div class="card-header" :class="{ current: current === 2 }">
      <span class="number">2</span> Pagamento
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      default: 1
    }
  },
  methods: {
    click1() {
      if (this.current === 2) {
        this.$emit("back");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.checkout-card-header {
  .card-header {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    opacity: 0.3;
    background: transparent;
    border: none;

    @media screen and (max-width: 991px) {
      padding: 12px;
      font-size: 14px;
    }

    .number {
      min-width: 32px;
      min-height: 32px;
      max-width: 32px;
      max-height: 32px;
      border-radius: 32px;
      color: white;
      background: #272d32;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      @media screen and (max-width: 991px) {
        min-width: 25px;
        min-height: 25px;
        max-width: 25px;
        max-height: 25px;
        border-radius: 25px;
      }
    }

    &.current {
      opacity: 1;
      color: #3cc13b;

      .number {
        background: #3cc13b;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }
}
</style>
