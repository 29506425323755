<template>
  <div class="template-default">
    <nav class="navbar navbar-expand-lg bg-white d-lg-block d-none">
      <div class="container">
        <div class="navbar-brand mr-5">
          <img class="logo" src="@/assets/logo.png" />
        </div>
      </div>
    </nav>

    <div class="container py-lg-5">
      <h1 class="mb-4 d-none d-lg-block">Finalizar compra</h1>
      <slot />
    </div>

    <footer class="bg-dark text-white py-5 text-center text-lg-left">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <img class="img-fluid" src="@/assets/images/odec.png" />
          </div>
          <div class="col-lg-3 d-flex flex-column">
            <a
              class="text-light font-weight-bold text-decoration-none py-1"
              href="https://app.seminariodefilosofia.org/termos-de-uso"
              target="_blank"
            >
              Termos de uso
            </a>
            <a
              class="text-light font-weight-bold text-decoration-none py-1"
              href="https://app.seminariodefilosofia.org/politica-de-privacidade"
              target="_blank"
            >
              Política de privacidade
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.template-default {
  nav {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

    .logo {
      max-height: 80px;
    }
  }

  /deep/ .nav-link {
    transition: 0.2s color;
    margin-right: 16px;
    color: #979797;
  }

  /deep/ .router-link-exact-active {
    font-weight: bold;
    color: #0088f2;
  }

  /deep/ .card {
    @media screen and (max-width: 991px) {
      border-radius: 0 !important;
      box-shadow: none !important;
    }
  }

  @media screen and (max-width: 991px) {
    background: white;

    .container {
      min-width: 100%;
      max-width: 100%;
      padding: 0;
      overflow: hidden;
    }
  }
}
</style>
