<template>
  <div id="app">
    <component :is="template">
      <router-view :key="$route.fullPath" />
    </component>

    <snackbar />
  </div>
</template>

<script>
import { getToken } from "./setup/api";

export default {
  computed: {
    template() {
      return this.$route.meta.template;
    }
  },
  watch: {
    $route: function() {
      window.scrollTo(0, 0);
    }
  }
};
</script>
